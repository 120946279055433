<template>
  <div>
    <v-row no-gutters>
      <v-col></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table :items="purchaseOrders" :headers="orderHeaders">
            <template v-slot:[`item.date`]="{item}">
                {{toDateString(item.date)}}
            </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fetchGET, toDateString } from "../js/functions"
export default {
  name: "purchaseOrders",
  data() {
    return {
      purchaseOrders: [],
      orderHeaders: [
        { text: "Status", value: "status" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Stad", value: "supplier.city" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Print", value: "printed", width: 50, sortable: false },
        { text: "Email", value: "emailed", width: 50, sortable: false },
        { text: "", value: "actions", width: 110, sortable: false }
      ],
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["orderNumber"],
        sortDesc: [true]
      }
    }
  },
  methods: {
    toDateString(val) {
      return toDateString(val)
    },
    async fetchPurchaseOrders() {
      let response = await fetchGET("fetchPurchaseOrders", {
        options: this.options
      })
      this.purchaseOrders = response[0].results
    }
  },
  mounted() {
    this.fetchPurchaseOrders()
  }
}
</script>

<style scoped>
</style>